.route-Login .container .card:first-child {
  background-color: transparent;
  border: 0; }

.route-Login .container .card:last-child {
  border-left: 1px solid #c2cfd6 !important; }

.route-Login .container h4 {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.route-Login .container img.logo {
  max-width: 250px; }
