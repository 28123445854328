.refreshButton {     
    width: 60px;
}

.chartContainer {  
    width: 100%;
}

.inputFilter {
    height: 35px;
    margin-right: 6px;
    max-width: 200px;
}

.inputDateFilter {
    max-width: 140px !important;
}

.dropDownToggle {
    background-color: white;
}

.loadingLabel {
    margin: 120px 0;
    width: 100%;
    text-align: center;
}

.tableDashboardResults
{
    margin-top: 30px;
}

.errorLabel
{
    color: rgb(129, 25, 25);
}

