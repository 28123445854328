main > header {
  padding: 0.75rem 1rem;
  background-color: white;
  border-bottom: 1px solid #e9ecef;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between; }

.run-info {
  text-align: right; }
